import './scss/rivandy.scss';
import React from 'react';
import VideoPlayer from './VideoPlayer';
import {useSelector } from 'react-redux';
import Zoom from 'react-reveal/Zoom';
import CardProfile from './CardProfile';

const MoreVideo = ({styleToApply}) => {
  const youtubeVideo1Id = 'L8TsXWboDvg'; // Remplacez par l'ID de votre vidéo YouTube
  const youtubeVideo2Id = 'PxgmXrPtxjc'; // Remplacez par l'ID de votre vidéo YouTube
  const youtubeVideo3Id = 'cjWNqFx3yCw'; // Remplacez par l'ID de votre vidéo YouTube
  const settings = useSelector(state => state);

  return (

    <section id="moreVideo" style={styleToApply}>
            <article className='flex'>
              <aside className='firstVideoAside'>
              <CardProfile></CardProfile>
              </aside>
              <aside className='moreVideoAside flex column'>
                <article id="video2"><VideoPlayer videoId={youtubeVideo2Id} isAutoPlay={false}  width={window.screen.width > 900 ? window.screen.width/3 : window.screen.width} height={window.innerHeight/3} /></article>
                <article id="video3"><VideoPlayer videoId={youtubeVideo3Id} isAutoPlay={false}  width={window.screen.width > 900 ? window.screen.width/3 : window.screen.width} height={window.innerHeight/3} /></article>
              </aside>
            </article>
            <article className="explore-section">
                <aside className='lastAsidePreview'>
                <h2 className={settings.factionSelected === 1 || settings.factionSelected === 0 ? 'rivandy-color':'dechys-color'}><Zoom right cascade>Explore more...</Zoom></h2>
                <p>Discover Rivandy by watching the latest game videos, audios.</p>
                </aside>
            </article>
        </section>
  );
}

export default MoreVideo;
