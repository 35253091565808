import React, { useState, useEffect, useRef } from 'react';
import Video from './Video';
import Faction from './Faction';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Jump from 'react-reveal/Jump';
import MoreVideo from './MoreVideo';
import Characters from './Characters';
import Spirits from './Spirits';
import Music from './Music';

import { Bounce } from 'react-reveal';
function Accueil() {
    const settings = useSelector(state => state);
    const mainSectionRef = useRef(null);
    const [vh, setVh] = useState(window.innerHeight * 0.01);
    const [pageIndex, setPageIndex] = useState(0);

    // Gérer la redimension de la fenêtre pour ajuster le vh
    useEffect(() => {
        const handleResize = () => {
            setVh(window.innerHeight * 0.01);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Gestion unifiée des événements de défilement
    useEffect(() => {
        let lastScrollTime = 0;

        const handleScroll = (event) => {
            event.preventDefault();
            const now = Date.now();
            if (now - lastScrollTime < 500) return; // Throttle de 1 seconde
            lastScrollTime = now;

            const direction = event.deltaY > 0 ? 1 : -1;
            setPageIndex(prevPageIndex => Math.max(0, Math.min(prevPageIndex + direction, window.innerWidth < 900 ? 5:4)));
        };

        const handleKeyDown = (event) => {
            if (event.keyCode === 38 || event.keyCode === 40) {
                event.preventDefault();
                const direction = event.keyCode === 40 ? 1 : -1;
                setPageIndex(prevPageIndex => Math.max(0, Math.min(prevPageIndex + direction, window.innerWidth < 900 ? 5:4)));
            }
        };

        window.addEventListener('wheel', handleScroll, { passive: false });
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        // Fonction pour faire défiler vers la section correspondante
        document.body.scrollTo({
            behavior: 'smooth',
            top: vh * 100 * pageIndex
        });
    }, [pageIndex, vh]);

    const sectionStyle = {
        height: `calc(${vh}px * 100)`,
    };

    return (
        <React.Fragment>
            <section id="main-section" ref={mainSectionRef} style={sectionStyle}>
            <article id="rivandy-logob">
                <img src={ process.env.PUBLIC_URL + (settings.factionSelected === 1 || settings.factionSelected === 0 ? "/img/rivandy.png": "/img/rivandyd.png")}></img>
                <p className="selected-explore-p">Battle royale | Dungeon Crawler</p>
                <a className="discord" href="https://discord.gg/RmubB8KhRW" target="_blank">Join Discord</a>
            </article>
            <Fade><video id="bg-1"
                width="100%"
                height="100%"
                preload="metadata"
                playsInline="true"
                loop="true"
                muted="true"
                autoPlay="true"
                data-object-fit="cover"
                data-object-position="center center"
              >
                <source src={process.env.PUBLIC_URL + '/video/preview.mp4'} type="video/mp4" />
                Votre navigateur ne prend pas en charge la lecture de la vidéo.
              </video>
                </Fade>
                <article className="explore-section">
                    <aside className='aside-small'>
                        <h2 className={settings.factionSelected === 1 || settings.factionSelected === 0 ? 'rivandy-color' : 'dechys-color'}><Zoom right cascade>THE CHOICE BELONGS TO YOU</Zoom></h2>
                        <p>You have the choice to join the circle of Rivandy as a benevolent guardian or become a corrupted Dechys, seeking to sow chaos and absorb all souls for your own quest for power.</p>
                    </aside>
                    
                </article>
                <Jump forever duration={3500}>
                <button id="buttonButtom" onClick={() => {document.body.scrollTo({
                    behavior: 'smooth',
                    top: window.innerHeight,
                })}}>
                        <svg fill="#ffd700" width="6vh" height="6vh" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"></path> </g></svg>
                        </button>
                    </Jump>
            </section>
            <Characters styleToApply={sectionStyle}></Characters>
            <Spirits styleToApply={sectionStyle}></Spirits>
            <Faction styleToApply={sectionStyle}></Faction>
            <MoreVideo styleToApply={sectionStyle}></MoreVideo>
            <Music styleToApply={sectionStyle}></Music>

            <Bounce left cascade>
            <nav id="leftMenu">
                <button className={pageIndex == 0 ? "hoverLeftMenu" : ""} onClick={() => {setPageIndex(0); document.body.scrollTo({ behavior: 'smooth',top: vh*100*0,})}}><img src="/img/rivandy-s.png"></img></button>
                <button className={pageIndex == 1 ? "hoverLeftMenu" : ""} onClick={() => {setPageIndex(1); document.body.scrollTo({ behavior: 'smooth',top: vh*100*1,})}}><svg viewBox="0 0 512 512" fill="white" width="150px" height="150px" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="white" d="M262.406 17.188c-27.22 8.822-54.017 28.012-72.375 55.53 17.544 47.898 17.544 57.26 0 105.157 19.92 15.463 40.304 24.76 60.782 27.47-2.063-25.563-3.63-51.13 1.125-76.69-13.625-1.483-23.374-5.995-37-13.874V82.563c35.866 19.096 61.84 18.777 98.813 0v32.22c-13.364 6.497-21.886 11.16-35.25 13.218 3.614 25.568 3.48 51.15 1.375 76.72 18.644-3.265 37.236-12.113 55.5-26.845-14.353-47.897-14.355-57.26 0-105.156-16.982-28.008-47.453-46.633-72.97-55.532zm-129.594 8.218c-25.906 110.414-27.35 215.33-27.4 330.922-18.84-1.537-37.582-5.12-56.027-11.12v28.554h69.066c8.715 35.025 6.472 70.052-1.036 105.078h28.13c-7.195-35.026-8.237-70.053-.872-105.078h68.904v-28.555c-18.49 4.942-37.256 8.552-56.097 10.46.082-114.94 2.496-223.068-24.667-330.26zm89.47 202.375c0 117.27 25.517 233.342 120.155 257.97C446.62 464.716 462.72 345.374 462.72 227.78H222.28z"></path></g></svg></button>
                <button className={pageIndex == 2 ? "hoverLeftMenu" : ""} onClick={() => {setPageIndex(2); document.body.scrollTo({ behavior: 'smooth',top: vh*100*2,})}}><svg version="1.1" id="_x32_" width="150px" height="150px" viewBox="0 0 512 512" fill="white">
<g>
	<path class="st0" d="M405.878,357.099c-3.641-41.297-29.703-99.953-92.734-102.781c-92.922,0.25-106.391,72.141-97.656,103.984   c7.328,26.75,26.781,48.078,54.422,45.656s48.469-26.844,45.656-54.438c-0.484-29.266,24.344-28.5,25.094,3.875   c1.016,43.094-28.094,80.922-71.031,84.703c-42.938,3.75-77.531-28.547-84.719-71.031c-4.344-25.781,0.594-75.172,44.438-113.766   c56.344-49.594,117.719,9.875,112.734-70.578c-5.031-81.188-25.438-82.906-78.063-114.531   c-34.797-20.922-40.75-54.109-38.797-67.109c0.078-0.625,0.078-1.563-1.156-0.781c-32.594,20.469-32.859,58-13.813,87.25   c9.141,14.016,28.031,21.234,29.969,43.422c2.641,30.094-14.672,39.281-28.938,51.703c-14.25,12.422-31.219,25.75-56.813,52.078   c-40.875,42.063-51.625,95.219-48.359,140.266c3.609,41.281,23.625,77.203,53.031,101.875   c29.453,24.688,68.313,38.141,109.594,34.516c41.297-3.625,77.203-23.641,101.906-53.063   C395.315,428.911,409.487,398.38,405.878,357.099z"/>
</g>
</svg></button>
                <button className={pageIndex == 3 ? "hoverLeftMenu" : ""} onClick={() => {setPageIndex(3); document.body.scrollTo({ behavior: 'smooth',top: vh*100*3,})}}><svg fill="white" width="150px" height="150px" viewBox="0 -8 72 72" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title>road-sign</title><path d="M36,3.76C34.79,6.6,31,9,26.77,9a11.55,11.55,0,0,1-6.93-1.73l-5.77,7.51a9.44,9.44,0,0,1,2.09,1.73H55.84a9.44,9.44,0,0,1,2.09-1.73L52.16,7.22A11.55,11.55,0,0,1,45.23,9C41,9,37.21,6.6,36,3.76Zm-18.45,15a9.85,9.85,0,0,1,.56,3.46c0,4.53-4,9-4,13.27,0,16.43,16.16,10.39,21.93,16.74,5.77-6.35,21.93-.31,21.93-16.74,0-4.27-4-8.74-4-13.27a9.85,9.85,0,0,1,.56-3.46Z"></path></g></svg></button>
                <button className={pageIndex == 4 || pageIndex === 5 ? "hoverLeftMenu" : ""} onClick={() => {setPageIndex(4); document.body.scrollTo({ behavior: 'smooth',top: vh*100*4,})}}><svg viewBox="0 0 24 24" fill="white" width="150px" height="150px" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.75 12.508L21.25 9.108V14.7609C20.7449 14.4375 20.1443 14.25 19.5 14.25C17.7051 14.25 16.25 15.7051 16.25 17.5C16.25 19.2949 17.7051 20.75 19.5 20.75C21.2949 20.75 22.75 19.2949 22.75 17.5C22.75 17.5 22.75 17.5 22.75 17.5L22.75 7.94625C22.75 6.80342 22.75 5.84496 22.6696 5.08131C22.6582 4.97339 22.6448 4.86609 22.63 4.76597C22.5525 4.24426 22.4156 3.75757 22.1514 3.35115C22.0193 3.14794 21.8553 2.96481 21.6511 2.80739C21.6128 2.77788 21.573 2.74927 21.5319 2.7216L21.5236 2.71608C20.8164 2.2454 20.0213 2.27906 19.2023 2.48777C18.4102 2.68961 17.4282 3.10065 16.224 3.60469L14.13 4.48115C13.5655 4.71737 13.0873 4.91751 12.712 5.1248C12.3126 5.34535 11.9686 5.60548 11.7106 5.99311C11.4527 6.38075 11.3455 6.7985 11.2963 7.25204C11.25 7.67831 11.25 8.19671 11.25 8.80858V16.7609C10.7448 16.4375 10.1443 16.25 9.5 16.25C7.70507 16.25 6.25 17.7051 6.25 19.5C6.25 21.2949 7.70507 22.75 9.5 22.75C11.2949 22.75 12.75 21.2949 12.75 19.5C12.75 19.5 12.75 19.5 12.75 19.5L12.75 12.508Z" fill="#ffffff"></path> <path d="M7.75 2C7.75 1.58579 7.41421 1.25 7 1.25C6.58579 1.25 6.25 1.58579 6.25 2V7.76091C5.74485 7.4375 5.14432 7.25 4.5 7.25C2.70507 7.25 1.25 8.70507 1.25 10.5C1.25 12.2949 2.70507 13.75 4.5 13.75C6.29493 13.75 7.75 12.2949 7.75 10.5V5.0045C8.44852 5.50913 9.27955 5.75 10 5.75C10.4142 5.75 10.75 5.41421 10.75 5C10.75 4.58579 10.4142 4.25 10 4.25C9.54565 4.25 8.9663 4.07389 8.51159 3.69837C8.0784 3.34061 7.75 2.79785 7.75 2Z" fill="#ffffff"></path> </g></svg></button>
            </nav>
            </Bounce>
        </React.Fragment>
    );
}

export default Accueil;